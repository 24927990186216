@use '../setup/variables' as v;
@use './helpers';
// @use './functions' as f;

.btn {
  // @extend .fs20;
  // font-size: 17px;
  font-size: var(--fs-base);

  border-radius: v.$btn-radius;
  border: none;
  cursor: pointer;
}

.with-padding {
  padding: 0.75em 1.68em;
}

.light-btn {
  @extend .btn;
  @extend .with-padding;
  // box-shadow: v.$box-shadow;
  background-color: v.$clr-grey;
  &.active {
    outline: auto;
    outline-color: v.$clr1;
  }
}
.button-container {
  display: flex;
  justify-content: space-between;
}
.selected-option {
  margin-top: 20px;
}
.custom-button {
  width: 115px;
  height: 42px;
  /* Add any other styles you want for this button */
  background-color: #15120a; /* Example background color */
  color: #fff; /* Example text color */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}
.primary-btn {
  @extend .btn;
  @extend .with-padding;
  font-family: almoni-bold;
  // display: inline-block;
  display: block;
  max-width: max-content;
  color: v.$clr-dark;
  background-color: v.$clr2;
  // word-break: break-all;
  // word-break: normal;

  // margin: 0 auto;
}
.highlighted {
  /* Your highlighted button styles */
  background-color: #4CAF50;
  color: white;
}
.highlighted-blue {
  /* Your highlighted button styles */
  background-color: #4CAF50;
  color: white;
}
.primary-btn-blue {
  @extend .primary-btn;
  color: v.$clr-light;
  background-color: v.$clr1;

  // margin: 0 auto;
}

.trans-btn {
  @extend .btn;
  background-color: transparent;
}

.small-btn {
  @extend .btn;
  width: 20px;
  height: 30px;
  background-color: transparent;
}
.close-btn {
  @extend .small-btn;
  max-width: 16px;
  // font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
}

.round-btn {
  @extend .fs20;
  font-family: 'Courier New', Courier, monospace;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: v.$clr1;
  // font-weight: bolder;
  background-color: transparent;
  border: 1.2px solid v.$clr1;
  &:disabled {
    color: v.$clr-grey-medium;
    border-color: v.$clr-grey-medium;
  }
}

.square-button {
  @extend .flex-col-center-center;
  border: none;
  width: 5.8rem;
  height: 4rem;
  padding: 10px 5px;
  border-radius: v.$card-radius;
  background-color: v.$clr-grey-light;
  cursor: pointer;

  &.active {
    border: 1px solid black;
  }
}

.payment-btn {
  @extend .btn;
  @extend .with-padding;
  background-color: transparent;
  border: 1.5px solid v.$clr-dark;
  &.ltr {
    @extend .ltr-element;
  }
  &.active {
    background-color: v.$clr2;
    color: v.$clr-dark;
    border-color: transparent;
  }
  &:disabled {
    background-color: v.$clr-grey-medium;
    border-color: transparent;
    cursor: not-allowed;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: v.$small-gap;
  width: 9em;
  img {
    width: 15px;
  }
}

.coupon-btn {
  @extend .btn;
  @extend .with-padding;
  background-color: transparent;
  // border: 1.5px solid v.$clr-dark;
  &.ltr {
    @extend .ltr-element;
  }
  // display: flex-start;
  // align-items: center;
  // justify-content: center;
  // gap: v.$small-gap;
  width: 10em;
  img {
    width: 15px;
  }
}
