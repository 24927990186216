@use '../setup/' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  min-height: 100vh;
  font-size: var(--fs-base);
  // font-size: 17px;
  // @media (min-width: $large-screen) {
  //   font-size: 20px;
  // }
}

body {
  height: 100vh;
  overflow-x: hidden;
  line-height: 1.4;
  color: $clr-dark;
  // line-height: 2.2;
  // line-height: 150%;
  background-color: $clr-bg;
}

main {
  overflow: hidden;
}

a {
  // color: $clr1;
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}

img,
picture {
  display: block;
  width: 100%;
  // max-width: 100%;
  // width: 100%;
}
.iframe-container {
  // height: 100%;
}
iframe {
  border: none;
  max-width: 100%;
  // height: 100%;
}
.checkout-message-inline {
  text-align: center;
  h3 {
    color: $clr1;
  }
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 2px;
  width: max-content;
  img {
    width: 16px;
    height: auto;
    // height: 16px;
  }
  &.no-text {
    span {
      display: none;
    }
  }
  &.no-symbol {
    img {
      display: none;
    }
  }
  @media (min-width: $medium-screen) {
    flex-direction: row;
    gap: $small-gap;
    img {
      width: 32px;
    }
  }
}
// .toast {
//   display: flex;
//   align-items: center;
//   padding: 10px;
//   gap: 15px;
//   position: fixed;
//   top: 5;
//   right: 0;
//   background-color: #fedb4d;
//   border-radius: 10px;
//   font-weight: bold;
//   z-index: 2;
// }

.old-price {
  color: $clr-grey-dark;
  // margin-inline-end: $small-gap;
  text-decoration: none;
  position: relative;
  &::before {
    top: 45%;
    background: red;
    opacity: 0.7;
    content: '';
    width: 115%;
    position: absolute;
    height: 0.1em;
    border-radius: 0.1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
  }
}

.swiper {
  width: 100%;
  // padding-top: 50px;
  padding-bottom: $large-gap !important;
}

.swiper-slide {
  background-position: center;
  background-size: contain;
  max-width: 400px;
  height: 400px;
  @media (max-width: $medium-screen) {
    max-width: 60%;
    height: auto;
    // max-width: 600px;
    // height: 600px;
  }
}

.swiper-slide img {
  border-radius: $card-radius;
  display: block;
  width: 100%;
}

.Toastify__toast {
  max-width: 80%;
  margin: 0 auto;
  border-radius: 15px;
  background-color: $clr-grey-light;
  .Toastify__toast-body {
    text-align: center;
    color: $clr1;
    font-family: almoni-bold, sans-serif;
  }
}

.backdrop {
  position: fixed;
  background-color: grey;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100vw;
  height: 100%;
  opacity: 0.6;
  z-index: 9999;
}
