// Colors:
$clr1: #0071e3;
$clr1-dark: #174ea2;
$clr1-light: rgba(0, 122, 255, 0.17);
$clr2: #fedb4d;
$clr-bg: #ffff;
$clr-light: #ffff;
$clr-darkest: #000;
$clr-dark: #444444;

$clr-grey: #f6f6f6;
$clr-grey-light: #f9f9fc;
$clr-grey-medium: rgba(198, 198, 205, 0.708);
$clr-grey-dark: #5a5959;

//UI:
$box-shadow: 0 5px 20px #6c6b6b23;
$card-radius: 22px;
$btn-radius: 1.8em;

// LAYOUT:
$header-height: 4.5rem;
// $layout-padding: 1.3rem;
$layout-padding: 6vw;
$tablet-layout-padding: 12vw;
$web-layout-padding: 19vw;

$inside-gap: 1.2rem;

$small-gap: 0.7rem;
$medium-gap: 1.3rem;
$large-gap: 3rem;
// $vertical-padding: 3.4rem;

//FONT SIZE
$x-large: 2.38rem;
$large: 2rem;
$medium: 1.2rem;
$small: 15px;

//BREAKPOINTS
$medium-screen: 860px;
$large-screen: 1000px;
$x-large-screen: 1200px;
$x-x-large-screen: 2000px;
