@use '../setup/functions' as *;

// Font Sizes
.fs12 {
  font-size: rem(12px);
}
.fs14 {
  font-size: rem(14px);
}
.fs18 {
  font-size: rem(18px);
}
.fs20 {
  font-size: rem(20px);
}
.fs24 {
  font-size: rem(24px);
}
.fs28 {
  font-size: rem(28px);
}
.fs30 {
  font-size: rem(30px);
}
.fs34 {
  font-size: rem(34px);
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.ltr-element {
  direction: ltr;
  unicode-bidi: bidi-override;
}

// flex helpers
.flex {
  display: flex;
  &.row {
    flex-direction: row;
  }
  &.col {
    flex-direction: column;
  }
  &.j-center {
    justify-content: center;
  }
  &.j-between {
    justify-content: space-between;
  }
  &.a-center {
    align-items: center;
  }
  &.s-gap {
    gap: 1rem;
  }
  &.m-gap {
    gap: 2rem;
  }
  &.l-gap {
    gap: 3rem;
  }
}

.flex {
  display: flex;
}

.flex-col {
  @extend .flex;
  flex-direction: column;
}

.flex-center-center {
  @extend .flex;
  align-items: center;
  justify-content: center;
}

.flex-col-center {
  @extend .flex-col;
  align-items: center;
}

.flex-col-center-center {
  @extend .flex-center-center;
  flex-direction: column;
}

.simple-card {
  background-color: rgba(198, 198, 205, 0.708);
  padding: 1.5rem;
  border-radius: 22px;
  max-width: 100%;
  text-align: center;
  // flex-direction: column;
  // align-items: center;
  img {
    max-width: 50%;
  }
  // @media (min-width: 500px) {
  //   flex-direction: row;
  // }
}
