@use '../setup/variables' as v;
@use '../setup/mixins' as m;

// .horizontal-padding {
//   padding-left: v.$layout-padding;
//   padding-right: v.$layout-padding;

:root {
  --layout-padding: 5vw;
}

@media (min-width: v.$large-screen) {
  :root {
    --layout-padding: 19vw;
  }
}
// }

main {
  padding-top: v.$header-height + v.$medium-gap;
  // margin-top: v.$header-height;
  padding-bottom: v.$large-gap;
  display: grid;
  grid-template-columns: v.$layout-padding 1fr v.$layout-padding;
  grid-auto-rows: max-content;
  &.no-padding-bottom {
    padding-bottom: 0;
  }
  &.no-padding-top {
    padding-top: v.$header-height;
  }
  > * {
    grid-column: 2;

    &.full-width {
      grid-column: 1 /-1;
      &:not(.no-padding) {
        @include m.inline-layout-padding;
      }
    }
  }
  @media (min-width: v.$large-screen) {
    padding-top: v.$header-height * 1.5;
    grid-template-columns: v.$tablet-layout-padding 1fr v.$tablet-layout-padding;
  }

  @media (min-width: v.$x-large-screen) {
    grid-template-columns: v.$web-layout-padding 1fr v.$web-layout-padding;
  }
}
