@use '../setup/variables' as v;
@use '../setup/mixins' as m;
@use '../basics/helpers';

.footer {
  font-family: rubik;
  padding-block: v.$large-gap;
  @include m.inline-layout-padding;
  background-color: v.$clr-grey;
  overflow: hidden;
  &.pad-bottom {
    padding-bottom: v.$header-height;
  }
  .for-web {
    display: none;
  }
  .nav-items {
    display: flex;
    gap: v.$small-gap;
    span {
      display: none;
    }
  }
  img {
    width: 50px;
  }
  small {
    display: block;
    font-size: smaller;
  }
  nav {
    display: flex;
    gap: v.$medium-gap;
    // &.social {
    //   gap: v.$medium-gap;
    // }
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: v.$medium-gap;
  }
  hr {
    width: 100%;
    margin-block: v.$medium-gap;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: v.$small-gap;
    justify-content: space-between;
  }
  p {
    // word-wrap: break-word;
    &:not(.long) {
      // width: max-content;
    }
    // max-width: 100%;
  }

  .disabled {
    display: none;
  }
  a {
    &.active {
      font-weight: bold;
      color: v.$clr1;
    }
  }
}

@media (min-width: v.$medium-screen) {
  .footer {
    .for-web {
      display: block;
    }
    .for-mobile {
      display: none;
    }
    img {
      width: 100px;
    }
    hr {
      // width: 100%;
      margin-block: v.$large-gap;
    }
    section {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
