@use '../setup/variables' as v;

@font-face {
  font-family: almoni-bold;
  src: url(../fonts/almoni-bold-aaa.otf);
  font-display: swap;
}

@font-face {
  font-family: almoni-ultra-bold;
  src: url(../fonts/almoni-ultrabold-aaa.otf);
  font-display: swap;
}

@font-face {
  font-family: rubik;
  src: url(../fonts/Rubik-Regular.ttf);
  font-display: swap;
}

:root {
  --fs-x-x-large: 3.052rem;
  --fs-x-large: 2.441rem;
  --fs-large: 1.953rem;
  --fs-medium: 1.563rem;
  --fs-small: 1.25rem;
  --fs-base: calc(15px + 0.390625vw);
}

@media (min-width: v.$x-large-screen) {
  :root {
    --fs-x-x-large: 4.209rem;
    --fs-x-large: 3.157rem;
    --fs-large: 2.369rem;
    --fs-medium: 1.777rem;
    --fs-small: 1.333rem;
    --fs-base: calc(18px + 0.390625vw);
  }
}

body {
  font-family: rubik, sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: almoni-ultra-bold;
  }
  a {
    font-family: almoni-bold, sans-serif;
  }
}

h1 {
  font-size: var(--fs-x-large);
}
h2 {
  font-size: var(--fs-large);
}
h3 {
  font-size: var(--fs-medium);
}
h4 {
  font-size: var(--fs-small);
}
