@use '../setup/variables' as v;
// @use '../setup/mixins' as m;
@use '../basics/helpers';

$small-gap: v.$small-gap * 1.5;

.clear-form-el {
  outline: none;
  border: none;
}

textarea {
  // max-width: 100%;
  // border-radius: v.$card-radius;
  width: 100%;
  // padding: v.$small-gap;
}

input {
  @extend .clear-form-el;
  padding: v.$small-gap;
  border-radius: v.$btn-radius;
  background-color: v.$clr-grey-light;
  outline: 1px solid v.$clr-grey-medium;
  &:focus {
    outline-color: v.$clr1;
    // border: 1px solid v.$clr1;
  }
  &::placeholder {
    color: #5a595996;
  }
}

select {
  &.input {
    border-inline-end: 16px solid transparent;
    @extend input;
  }
  // @extend .clear-form-el;
  // color: v.$clr1;
}

form:not(.clean-form) {
  @extend .flex-col;
  font-size: 1rem;
  text-align: start;
  // > * {
  //   margin-bottom: 0.5rem;
  // }
  // input {
  //   @extend .input;
  // }
  label {
    // display: flex;
    // @extend .flex-col;
    margin-inline-start: $small-gap;
    &.error {
      color: red;
    }
  }
  &.simple-form {
    gap: v.$small-gap;
  }

  button {
    margin-top: 0.5rem;
    align-self: center;
  }
}
