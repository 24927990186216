@use '../setup/variables' as v;
@use '../setup/mixins' as m;
@use '../basics/helpers';

.admin-page {
  section {
    padding-block: v.$medium-gap;
  }
  select {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    min-height: 38px;
    padding: 5px;
    // padding: 0.3rem;
    // font-size: 1rem;
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: v.$small-gap;
    margin-bottom: v.$medium-gap;
    nav {
      display: flex;
      gap: 1.5rem;
    }
    a {
      padding-bottom: v.$small-gap;
      &.active-nav-link {
        color: v.$clr1;
        border-bottom: 1px solid v.$clr1;
      }
    }
  }
  @include m.ltr-element;
  padding-top: v.$header-height * 1.5;
  h1 {
    margin: 0 auto;
    margin-top: v.$medium-gap;
    margin-bottom: v.$large-gap;
  }

  h3 {
    font-size: larger;
    margin-bottom: v.$medium-gap;
  }
  label img {
    max-width: 120px;
    margin: 0 auto;
    // display: inline;
  }
}
.selection {
  // display: flex;
  // grid-template-columns: repeat(auto-fit, minmax(120px, 300px));
  // gap: v.$medium-gap;
  // justify-content: space-evenly;
  // div {
  //   // width: 100%;
  // }
}

.row-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: v.$medium-gap;

  li {
    @include m.card;
    justify-content: space-between;
    @extend .flex-col;
    gap: v.$small-gap;
    text-align: center;
    b {
      color: v.$clr1;
    }
  }
}
.analytics-results {
  @extend .flex-col;
  gap: v.$large-gap;
  padding-inline: 30px;
  @media (min-width: v.$large-screen) {
    padding-inline: 100px;
  }
  // .selection,
  // h2,
  // h3,
  // ul {
  //   @include m.inline-layout-padding;
  // }
}
