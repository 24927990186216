@use '../basics/helpers';
@use 'variables' as v;
@use 'functions' as f;

@mixin simple-section {
  @extend .flex-col;
  gap: v.$small-gap;
  margin-bottom: v.$medium-gap;
}

@mixin card($gap: 16px) {
  border-radius: v.$card-radius;
  padding: v.$medium-gap;
  background-color: v.$clr-grey-light;
}

@mixin dark-section() {
  color: v.$clr-light;
  background-color: v.$clr-darkest;
}

// @mixin regular-fonts {
//   font-family: SF, Ploni-regular, sans-serif;
// }

// @mixin header-fonts {
//   font-family: Kaleko-heavy, Ploni-bold, sans-serif;
// }

@mixin font-size($size) {
  @if $size == 'large' {
    font-size: v.$large;
    @media (min-width: v.$medium-screen) {
      font-size: 2.36rem;
    }
  }

  @if $size == 'medium' {
    font-size: v.$medium;
    @media (min-width: v.$medium-screen) {
      font-size: 2rem;
    }
  }
}

@mixin gradient-text {
  background-image: linear-gradient(
    90deg,
    #1e3791 23.6%,
    #2948b1 41.89%,
    #3153c6 60.17%,
    #385fda 79.33%
  );
  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

@mixin bottom-fixed-container {
  @media (max-width: v.$medium-screen) {
    position: fixed;
    bottom: 0;
    right: 0;
    border-top: 0.5px solid v.$clr-grey-light;
    background-color: v.$clr-grey;
  }
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$small-gap v.$layout-padding;
  @media (min-width: v.$medium-screen) {
    gap: v.$small-gap;
    flex-direction: column;
  }
}

@mixin ltr-element {
  @extend .ltr-element;
}

@mixin paragraphs-section {
  @extend .flex-col;
  align-items: flex-start;
  gap: v.$medium-gap;
  line-height: 1.4;
  h3 {
    // font-size: v.$medium;
  }
  @media (min-width: v.$medium-screen) {
    // max-width: v.$medium-screen;
    h3 {
      // font-size: 34px;
    }
  }
}

@mixin inline-layout-padding {
  padding-inline: v.$layout-padding;
  @media (min-width: v.$medium-screen) {
    padding-inline: v.$tablet-layout-padding;
  }
  @media (min-width: v.$large-screen) {
    padding-inline: v.$web-layout-padding;
  }
}

@mixin block-big-padding {
  padding-block: v.$medium-gap;
  @media (min-width: v.$medium-screen) {
    padding-block: v.$large-gap;
  }
}

// Mixin for removing hover effects on iphone screen
// @mixin hover-supported {
//   @media not all and (pointer: coarse) {
//     &:hover {
//       @content;
//     }
//   }
// }
